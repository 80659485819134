/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import loadScript from "../../react/loadscript";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import ArrowRightICon from "../../components/common/Icons";
import styled from "styled-components";
import { AppSwitchTop } from "./chatbotmarketplacesecond";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { useMedia } from "use-media";
import { RequestForm } from "../../components/form";
import { DownArrowCircle } from "../../react/Icons";
import { ChatbotList } from "./chatbotmarketplacesecond";

const topImage = require("../../assets/img/feature/marketplace_template/marketplace_assistant_header.png");
const AppIcon = require("../../assets/images/icons-web/bamboo.svg");
const Time = require("../../assets/images/icons-web/timer.svg");
const Dialog = require("../../assets/images/icons-web/Dialog.svg");
const Automation = require("../../assets/images/icons-web/Automation.svg");
const icon = require("../../assets/images/icons-web/bot.svg");
export const cardOne = require("../../assets/img/feature/home/sec2.png");
export const cardTwo = require("../../assets/img/feature/home/sec3.png");
const BackArrow = require("../../assets/images/icons-web/back_arrow.svg");
const RightArrow = require("../../assets/images/icons-web/right_arrow.svg");

export default function MarketPlace(props) {
  return (
    <>
      <TitleAndMetaTags
        title="Pre-built automations and actions  | Workativ Automate"
        description="Integrate chatbot with apps and use pre-built workflow actions and tasks from marketplace easily. Explore the full potential of chatbot based automations instantly."
        keywords={[
          "IT Helpdesk Chatbot",
          "HR Chatbot",
          "Workativ Integrations",
        ]}
        ogTitle="Pre-built automations and actions  | Workativ Automate"
        ogDescription="Integrate chatbot with apps and use pre-built workflow actions and tasks from marketplace easily. Explore the full potential of chatbot based automations instantly."
      />

      <Container>
        <Layout
          backgroundColor={" bg_slider_home pad_0"}
          logoFor="ASSISTANT"
          product="ASSISTANT"
        >
          <PostHeaderSection />
          <AppDescription />
          <CardsThree />
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
export const cardData = [
  {
    image: cardOne,
    header: "Workativ for IT Support",
    content: `Deliver faster and superior IT support, powered by conversational ai and app workflow automation. Augment your IT Helpdesk team with intelligent chatbot to scale your support easily in a few clicks.  `,
    content_1: `No coding required.`,
    linkAddress: "/conversational-ai-platform/it-helpdesk-chatbot",
    backgroundClass: "bg_card_1",
  },
  {
    image: cardTwo,
    header: "Workativ for HR Support",
    content: `Transform your employee experience with our AI-powered HR chatbot with prebuilt HR workflow automation. Free up your HR staff to focus on high value work.`,
    content_1: ` No coding required.`,
    linkAddress: "/conversational-ai-platform/hr-chatbot",
    backgroundClass: "bg_card_2",
  },
];
const appdescription = [
  {
    image: icon,
    content: "Virtual Agent",
    url: "",
    link: "Download this Bot",
    count: "05 Downloads",
  },
  {
    image: icon,
    content: "HR Bot",
    url: "",
    link: "Download this Bot",
    count: "05 Downloads",
  },
  {
    image: icon,
    content: "Jira Service Management",
    url: "",
    link: "Download this Bot",
    count: "05 Downloads",
  },
  {
    image: icon,
    content: "Freshdesk Bot",
    url: "",
    link: "Download this Bot",
    count: "05 Downloads",
  },
  {
    image: icon,
    content: "OTRS Bot",
    url: "",
    link: "Download this Bot",
    count: "05 Downloads",
  },
  {
    image: icon,
    content: "ServiceNow Bot",
    url: "",
    link: "Download this Bot",
    count: "05 Downloads ",
  },
  {
    image: icon,
    content: "Zendesk Bot",
    url: "",
    link: "Download this Bot",
    count: "05 Downloads",
  },
  {
    image: icon,
    content: "Digital Assistant",
    url: "",
    link: "Download this Bot",
    count: "05 Downloads ",
  },
  {
    image: icon,
    content: "Ticketing Chatbot",
    url: "",
    link: "Download this Bot",
    count: "05 Downloads",
  },
  {
    image: icon,
    content: "Solarwinds Bot",
    url: "",
    link: "Download this Bot",
    count: "05 Downloads ",
  },
];
function PostHeaderSection() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <RegisterSection
      rightImage={topImage}
      backgroundColor={" bg_slider_home "}
      altImage="App Workflow Marketplace for Chatbot"
      additionalClass="marketplace-assist-header-bottom marketplace-mobile-center-text"
    >
      <RegisterSection.FormWithContent>
        <RegisterSection.MainHeader>
          Jump-start your chatbot with easy-to use pre-built bot & Pre-built app
          workflow templates.
        </RegisterSection.MainHeader>
        <RegisterSection.SubHeader>
          JJoin several leading companies using workativ in providing smarter employee experience using conversational ai chatbots with app workflow automation.
        </RegisterSection.SubHeader>
      </RegisterSection.FormWithContent>
    </RegisterSection>
  );
}
function AppDescription() {
  return (
    <div className="overall_wrapper">
      <div className="market_place">
        <>
          <div className="marketplace_wrapper app_workflow_section_marketplace background_marketplace_assist">
            <div className="container">
              <div class="row">
                <AppSwitchTop />

                <div className="col-lg-3 app-market-main-container-left-side">
                  <ChatbotList />
                </div>

                <div className="col-lg-9 app-market-main-container app-market-main-container-mobile-chatbot">
                  <div className="search_marketpalce">
                    <input
                      className="search_click"
                      type="search"
                      placeholder="Search bot"
                      onChange={(e) =>
                        setSearch({ ...search, searchText: e.target.value })
                      }
                    />
                    <span className="search_icon">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fal"
                        data-icon="search"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        class="svg-inline--fa fa-search fa-w-16 fa-2x"
                      >
                        <path
                          fill="currentColor"
                          d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div className="chatbot-section-marketplace_heading chatbot-marketplace-first-container">
                    {/* <img src={AppIcon}></img>
                  <h5 className="font-section-normal-text mb-0 chatbot-names-marketplace-text">
                    BambooHR - Workflows <span>(06)</span>
                  </h5> */}
                  </div>
                  <div className="chatbot_main_marketplace">
                    {appdescription.map((data) => (
                      <div className="chatbot-card-div-marketplace">
                        <div className="chatbot-top-div-marketplace">
                          <img
                            src={data.image}
                            className=" app-chatbot-icon-marketplace"
                          ></img>
                          <p className="app-chatbot-content-marketplace font-section-normal-text-medium">
                            {data.content}
                          </p>
                        </div>

                        <div className="chatbot-bottom-div-marketplace font-name-smaller-text">
                          <a
                            className="chatbot-link-marketplace font-name-smaller-text-medium"
                            href={data.url}
                            style="text-decoration: none;"
                          >
                            {data.link}
                            <img src={RightArrow} className="arrow_right_link_marketplace"/>
                          </a>
                          <span className="chatbot-count-marketplace font-name-smaller-text">
                            {data.count}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <ShowMore />
                  <ReachToUsSection />
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
export function ShowMore() {
  return (
    <div className="w-100 float-left">
      <div className="show_more_videos font-section-normal-text show_more_marketplace_assist show_more_marketplace_assist_mobile">
        <button
          onClick={() => {
            setShowmore("show");
          }}
        >
          Show More
        </button>
      </div>
    </div>
  );
}
export function ReachToUsSection() {
  const [showModal, setShowModal] = useState(false);

  const [state, setState] = useState({ useCase: "", email: "" });

  function closemodel(callback) {
    document.getElementById("exampleModalCenter").className = "modal fade";
    document.getElementById("exampleModalCenter").style.display = "none";
    if (callback) {
      callback();
    }

    setShowModal(false);
  }

  function enableSubmit() {
    return state.useCase.length > 0 && state.email.length > 0;
  }

  useEffect(() => {
    // console.log("load scropt");
    if (!window._load_script) {
      loadScript("/marketPlaceScript.js")
        .then((script) => {
          // console.log("load scropt success");
          // console.log(script);
          // Grab the script object in case it is ever needed.
          // this.mapScript = script;
          // this.setState({ apiLoaded: true });
        })
        .catch((err) => {
          console.log("load scropt error");
          console.error(err.message);
        });
    }
  }, []);

  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <section className="apps_integrations bg_marketpalce">
      <div className="cogni_fav_apps ">
        <div className="container">
          <div className="marketplace_assist_reach_div">
            <div className="cc_feature_apps_header">
              {isSmall ? (
                <h3
                  className="font-section-sub-header
                "
                >
                 Didn’t find the Bot you were looking for? No worries, we will create one for you.
                </h3>
              ) : (
                <h3
                  className="font-section-sub-header
                "
                >
                  Didn’t find the Bot you were looking for? <br></br>No worries, we will create one for you.
                </h3>
              )}
              <div className="modal_submit_form marketplace_assist_reach_button marketplace_assist_reach_button_mobile">
                <button
                  type="button"
                  className="btn btn-primary font-section-normal-text"
                  data-toggle="modal"
                  data-target="#exampleModalCenter1"
                  onClick={() => setShowModal(true)}
                >
                  Reach out to us
                </button>

                <div
                  className="modal fade show"
                  id="exampleModalCenter1"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                  style={{ display: showModal ? "flex" : "none" }}
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div
                      className="modal-content modal-content-popup"
                      id="beta-detail-page"
                    >
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={() => closemodel()}
                        >
                          <span className="btn_close_popup" aria-hidden="true">
                            ×
                          </span>
                        </button>
                      </div>
                      <div className="modal-body-header">
                        <div className="modal-body">
                          <form
                            id="_form_22_"
                            className="_form _form_22 _inline-form  _dark"
                            noValidate
                          >
                            <input type="hidden" name="u" defaultValue={22} />
                            <input type="hidden" name="f" defaultValue={22} />
                            <input type="hidden" name="s" />
                            <input type="hidden" name="c" defaultValue={0} />
                            <input type="hidden" name="m" defaultValue={0} />
                            <input
                              type="hidden"
                              name="act"
                              defaultValue="sub"
                            />
                            <input type="hidden" name="v" defaultValue={2} />
                            <div className="_form-content">
                              <div className="_form_element _x41893381 _full_width _clear">
                                <div className="_form-title">
                                  Request for Use Case
                                </div>
                              </div>
                              <div className="_form_element _x65116826 _full_width _clear">
                                <div className="_html-code">
                                  <p>
                                    Tell us of your use case requirement in a
                                    few lines.
                                  </p>
                                </div>
                              </div>
                              <div className="_form_element _field16 _full_width ">
                                <label className="_form-label">
                                  Automation use case *
                                </label>
                                <div className="_field-wrapper">
                                  <textarea
                                    id="useCase"
                                    name="field[16]"
                                    placeholder="Automation use case "
                                    required
                                    defaultValue={""}
                                    onChange={(e) =>
                                      setState({
                                        ...state,
                                        useCase: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="_form_element _x68473619 _full_width ">
                                <label className="_form-label">Email*</label>
                                <div className="_field-wrapper">
                                  <input
                                    id="email"
                                    type="text"
                                    name="email"
                                    placeholder="Type your email"
                                    required
                                    onChange={(e) =>
                                      setState({
                                        ...state,
                                        email: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="_button-wrapper _full_width">
                                <button
                                  id="_form_22_submit"
                                  className="_submit"
                                  type="submit"
                                  disabled={!enableSubmit()}
                                  style={{
                                    pointerEvents: enableSubmit()
                                      ? "visible"
                                      : "none",
                                  }}
                                >
                                  Submit
                                </button>
                              </div>
                              <div className="_clear-element" />
                            </div>
                            <div
                              className="_form-thank-you"
                              style={{ display: "none" }}
                            />
                          </form>
                        </div>
                        <div className="modal_body_right">
                          <img
                            src={require("../../assets/images/landing/register.png")}
                            alt="register"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function CardsThree() {
  return (
    <section className="helpdesk_solutions card_two">
      <div className="suprcharging_benefits" id="numeric">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="main-slider-left">
                <h2 className="font-section-header marketplace-two-card-header">
                  Transform your workplace support with chatbot automation now
                </h2>
              </div>
              {/*How it Works Content*/}

              <div className="cards_wrapper_home">
                {cardData.map((cards) => {
                  let changeColor =
                    cards.backgroundClass === "bg_card_1"
                      ? "#F1FAF4 0% 0% no-repeat padding-box"
                      : "#FBF9F4 0% 0% no-repeat padding-box";
                  return (
                    <div
                      className={`${"col-lg-6 col-md-6 col-sm-6 col-xs-12"} ${
                        cards.backgroundClass
                      }`}
                      style={{ background: changeColor }}
                    >
                      <div className="benefits-workplace-content cogni_workspace_content">
                        <div className="cards_soon_wrapper">
                          <img src={cards.image} alt={cards.alt} />
                          {cards.cards_soon && (
                            <span className="cards_soon">
                              <div class="coming_soon_wrapper">
                                <span class="coming_soon">Coming soon</span>
                              </div>
                            </span>
                          )}
                        </div>
                        <h4 className="cards_slider_wrap font-section-sub-header-small-bold">
                          {cards.header}
                        </h4>
                        <p className="font-section-normal-text">
                          {cards.content}
                        </p>
                        <h6 className="font-medium font-section-normal-text-medium">
                          {cards.content_1}
                        </h6>
                        <a
                          href={cards.linkAddress}
                          className="url_manipulation font-section-normal-text-medium"
                        >
                          Learn more{" "}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
